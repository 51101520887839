import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { i18n } from "../../translate/i18n";

const EtapaPerfexSelect = ({ selectedEtapaPerfexIds, onChange, multiple = true, title, }) => {

	const handleChange = e => {
		onChange(e.target.value);
	};
    const etapasPerfex = [
        { titulo: "Draft", id: 6 },
        { titulo: "Sent", id: "sent" },
        { titulo: "Open", id: "open" },
    ];

	return (
		<div>
            <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel shrink={selectedEtapaPerfexIds && selectedEtapaPerfexIds.length > 0}>{title}</InputLabel>
                <Select
                labelId="select-etapa-perfex-label"
                id="select-etapa-perfex"
                label={title}
                multiple={multiple}
                value={selectedEtapaPerfexIds}
                onChange={handleChange}
                fullWidth
                MenuProps={{
                    anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                    },
                    transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                    },
                    getContentAnchorEl: null,
                }}
                >
                {/* {!multiple && <MenuItem value={null}>Nenhum</MenuItem>} */}
                {etapasPerfex.map((etapa) => (
                    <MenuItem key={etapa.id} value={etapa.id}>
                    {etapa.titulo}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </div>
	);
};

export default EtapaPerfexSelect;
