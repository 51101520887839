import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
const axios = require('axios');

const PipelineSelect = ({ selectedPipelineIds, onChange, multiple = true, title = i18n.t("pipelineSelect.inputLabel") }) => {
	const [pipelines, setPipelines] = useState([]);

	useEffect(() => {
		fetchPipelines();
	}, []);

	const fetchPipelines = async () => {
		try {

            const companyId = localStorage.getItem('companyId');
            const dataEmpresa = await api.get(`/queueIntegration/${companyId}`);
            const token = dataEmpresa.data.queueIntegrations[0].tokenCRM2You
            const empresa = dataEmpresa.data.queueIntegrations[0].empresaIdCRM2You


            const {data} = await axios.get(`https://crm2you.com.br/api/empresas/${empresa}/projetos`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });


            console.log(data)
			setPipelines(data);

		} catch (err) {
			toastError(err);
		}
	}

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
        <div>
            <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel shrink={selectedPipelineIds && selectedPipelineIds.length > 0}>{title}</InputLabel>
                <Select
                labelId="select-pipeline-label"
                id="select-pipeline"
                label={title}
                multiple={multiple}
                value={selectedPipelineIds}
                onChange={handleChange}
                fullWidth
                MenuProps={{
                    anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                    },
                    transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                    },
                    getContentAnchorEl: null,
                }}
                >
                {!multiple && <MenuItem value={null}>Nenhum</MenuItem>}
                {pipelines.map((pipeline) => (
                    <MenuItem key={pipeline.id} value={pipeline.id}>
                    {pipeline.nome}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </div>
	);
};

export default PipelineSelect;
