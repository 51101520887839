import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { i18n } from "../../translate/i18n";

const EtapaSelect = ({ selectedEtapaIds, onChange, multiple = true, title, etapas = i18n.t("etapaSelect.inputLabel") }) => {

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<div>
            <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel shrink={selectedEtapaIds && selectedEtapaIds.length > 0}>{title}</InputLabel>
                <Select
                labelId="select-etapa-label"
                id="select-etapa"
                label={title}
                multiple={multiple}
                value={selectedEtapaIds}
                onChange={handleChange}
                fullWidth
                MenuProps={{
                    anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                    },
                    transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                    },
                    getContentAnchorEl: null,
                }}
                >
                {!multiple && <MenuItem value={null}>Nenhum</MenuItem>}
                {etapas.map((etapa) => (
                    <MenuItem key={etapa.id} value={etapa.id}>
                    {etapa.titulo}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </div>
	);
};

export default EtapaSelect;
